import React, { useContext, useState } from 'react'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';

import './Login.css'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const Login = () => {

    const [error, setError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState(false);

    const navigate = useNavigate();

    const { dispatch } = useContext(AuthContext)


    const handleLogin = (e) => {
        e.preventDefault();


        signInWithEmailAndPassword(auth, username, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                dispatch({ type: 'LOGIN', payload: user })
                navigate('/')
            })
            .catch((error) => {
                setError(true)
            });
    }

    return (
        <div className='login' >
            <h1 className='login-heading'>Only for admin</h1>
            <form className='login-form' onSubmit={handleLogin}>
                <input type="text" placeholder='Username' className='login-input' onChange={e => setUsername(e.target.value)} />
                <input type="password" placeholder='Password'
                    className='login-input' onChange={e => setPassword(e.target.value)} />

                <button className='login-btn'>Login</button>
                {error && <span>Wrong email or password!</span>}
            </form>
        </div>
    )
}

export default Login