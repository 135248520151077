import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BsFillArrowLeftSquareFill } from 'react-icons/bs'
import '../AddNew/AddNew.css'

import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { db } from '../../firebase';


const EditData = () => {
    const [data, setData] = useState(null)

    const { id } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            let list = [];
            try {
                const querySnapshot = await getDocs(collection(db, "projects"));
                querySnapshot.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data().inputData })
                });

                setData(list)
            }
            catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, []);

    const navigate = useNavigate();




    const [additionalInputs, setAdditionalInputs] = useState([]);

    const [category, setCategory] = useState('');
    const [type, setType] = useState('video');
    const [mainTitle, setMainTitle] = useState('');
    const [sideTitle, setSideTitle] = useState('');
    const [description, setDescription] = useState('');
    const [projectType, setProjectType] = useState('');
    const [sideimages, setSideimages] = useState([]);



    const [placeholder, setplaceholder] = useState(false);

    const [error, setError] = useState(false);



    const inputData = {

        category: category,
        type: type,
        mainTitle: mainTitle,
        sideTitle: sideTitle,
        description: description,
        additionalImages: sideimages,
        projectType: projectType.split(','),
    }



    const submitHandler = async (e) => {
        e.preventDefault();
        if (mainTitle === '' || sideTitle === '' || description === '' || projectType === '') {
            setError(true);
            return;
        }

        setCategory(' ');
        setType(' ');
        setMainTitle(' ');
        setSideTitle(' ');
        setDescription(' ');
        setProjectType(' ');
        setSideimages([]);
        setAdditionalInputs([]);

        try {

            const id = Date.now().toString()

            await setDoc(doc(db, "projects", id), {
                inputData
            });

            navigate('/')
        }
        catch (err) {
            console.log(err);
        }

    }


    const handleAddInput = (e) => {
        e.preventDefault();

        // Create a new input field object
        const newInput = (
            <input
                key={additionalInputs.length}
                type="text"
                placeholder="Enter URL"
                className="input"
                onChange={(e) => handleInputChange(e, additionalInputs.length)}
            />

        );

        setAdditionalInputs([...additionalInputs, newInput]);
    };

    const handleInputChange = (e, index) => {

        const updatedInputData = { ...inputData };


        updatedInputData[`additionalInput${index}`] = e.target.value;

        const updatedSideImages = [...sideimages];
        updatedSideImages[index] = e.target.value;
        setSideimages(updatedSideImages);
    };

    const placeholderHandlerImage = () => {
        setplaceholder(true);
    }
    const placeholderHandlerVideo = () => {
        setplaceholder(false);
    }

    console.log(data)

    return (

        <section section className='add-new' >


            <div className="title">

                <Link to='/'><BsFillArrowLeftSquareFill className='icon' /></Link>

            </div>

            <form className='add-new-form' >
                <p>Project id is {id}</p>
                <h1>Edit Data</h1>
                <div className="service-name">
                    <div className="service">
                        <input type="radio" id="media" name="category" value="media" defaultChecked onChange={e => setCategory(e.target.value)} />
                        <label htmlFor="media">Media</label>
                    </div>
                    <div className="service">
                        <input type="radio" id="design" name="category" value="design" onChange={e => setCategory(e.target.value)} />
                        <label htmlFor="design">Design</label>
                    </div>
                </div>

                <div className="service-name service-type">
                    <div className="service">
                        <input type="radio" id="video" name="type" value="video" defaultChecked onChange={e => setType(e.target.value)} onClick={placeholderHandlerVideo} />
                        <label htmlFor="video">Video</label>
                    </div>
                    <div className="service">
                        <input type="radio" id="image" name="type" value="image" onChange={e => setType(e.target.value)} onClick={placeholderHandlerImage} />
                        <label htmlFor="image">Image</label>
                    </div>
                </div>

                <div className="input-data">
                    <div className="main">
                        <input type="text" placeholder={`${placeholder ? 'Enter URL of Thumbnail' : 'Enter Youtube URL'}`} className='input' onChange={e => setMainTitle(e.target.value)} />
                        <img src={mainTitle} alt="" />
                    </div>
                    <div className="side">

                        <input type="text" placeholder='Title' className='input' onChange={e => setSideTitle(e.target.value)} />
                        <input type="text" placeholder='Description' className='input input-desc' onChange={e => setDescription(e.target.value)} />
                    </div>
                </div>

                <div className="extra-data">
                    {additionalInputs}
                    <button className='add-input-btn' onClick={handleAddInput}>Add Images</button>
                    <div className="sideimages-image">
                        {sideimages.map((image, i) => (
                            <img src={image} alt='' key={i} className='image' />
                        ))}
                    </div>
                </div>



                <div className="project-type">

                    <div className='project-names'>
                        <span >Music Video</span>
                        <span >2D Animation</span>
                        <span>Motion Graphics</span>
                        <span>Animated Adverstisments</span>
                        <span>Explainer Videos</span>
                        <span>Video Editing</span>
                        <span>3D Animation</span>
                    </div>
                    <div className='project-names'>
                        <span>Machine-Product Design</span>
                        <span>FEA-CFD Analysis</span>
                        <span>Concept Development</span>
                        <span>Product Rendering</span>
                        <span>Electronics-Circuit Design</span>
                        <span>3D Animation</span>
                        <span>Architectural Walkthrough</span>
                    </div>
                    <input type="text" placeholder='Project-Type' className='input-project-type' onChange={e => setProjectType(e.target.value)} />

                    <p>Write Project-Type Comma Seperated from given above</p>
                </div>

                <button className='submit-btn' onClick={submitHandler}>Save Changes
                </button>

                {error && <span>Fill out all details</span>}
            </form>


        </section >
    )
}

export default EditData;