import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AddNew from './components/AddNew/AddNew';
import Admin from './pages/admin/Admin';
import Login from './pages/login/Login';

import './App.css';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import EditData from './components/EditData/EditData';
function App() {

  const { currentUser } = useContext(AuthContext)


  const RequiredAuth = ({ children }) => {
    return currentUser ? children : <Navigate to='/login' />
  }

  return (
    <Router >
      <Routes>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/' element={
          <RequiredAuth>
            <Admin />
          </RequiredAuth>
        }></Route>
        < Route path='/addnew' element={
          <RequiredAuth>
            < AddNew />
          </RequiredAuth>
        } ></Route>
        < Route path='/edit/:id' element={
          <RequiredAuth>
            < EditData />
          </RequiredAuth>
        } ></Route>
      </Routes >

    </Router >
  );
}

export default App;
