import React, { useState, useEffect } from 'react'
import './Data.css'
import YouTube from 'react-youtube'
import { db } from '../../firebase'
import { Link, useNavigate } from 'react-router-dom'


import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";





const Data = (props) => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            let list = [];
            try {
                const querySnapshot = await getDocs(collection(db, "projects"));
                querySnapshot.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data().inputData })
                });
                setData(list)
                // console.log(list.id);
            }
            catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, []);


    const deleteHandler = async (id) => {
        try {
            await deleteDoc(doc(db, "projects", id))
            console.log('deleted');
        } catch (err) {
            console.log(err);
        }
    }

    const editHandler = (id) => {
        console.log(id);
        // Use React Router to navigate to the EditData component with the correct id
        navigate(`/edit/${id}`);
    }

    return (
        <section>


            {/* DATA FROM MEDIA  */}
            <h1>All Projects</h1>
            {console.log(data)}

            {data.map((el, i) => (
                <div className="details" key={i}>
                    <div className="details-main">
                        <div className="image">

                            {el.type === 'video' ? <YouTube videoId={el.mainTitle} /> : <img src={el.mainTitle} alt="" />}
                        </div>
                        <div className="data">
                            <h1>{el.sideTitle}</h1>
                            <p>{el.description}</p>

                            <h2>Service</h2>
                            <h1>{el.category}</h1>

                            <h3>Project-Types</h3>
                            {el.projectType.map((el, i) => (
                                <i key={i}>{el},</i>
                            ))}

                        </div>

                    </div>

                    <div className="innerdata">
                        {el.additionalImages.map((el, i) => (
                            <img src={el} alt="" key={i} />
                        ))}

                    </div>
                    <Link to={`/edit/${data[i].id}`} >
                        <button className="delete-btn" onClick={() => editHandler(data[i].id)} >Edit</button>
                    </Link>
                    <button className="delete-btn" onClick={() => deleteHandler(data[i].id)}>Delete</button>
                </div>
            ))}


        </section>
    )
}

export default Data;