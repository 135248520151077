// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDK4iiKtaht6wvfskBCghamNkaXiSbw2eE",
    authDomain: "vutuk-api-v2.firebaseapp.com",
    projectId: "vutuk-api-v2",
    storageBucket: "vutuk-api-v2.appspot.com",
    messagingSenderId: "787993530057",
    appId: "1:787993530057:web:ee1ff6863865dfce870718",
    measurementId: "G-R0JDXV764B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth();
