import React from 'react'
import { Link } from 'react-router-dom';

import './Admin.css'
import Data from '../../components/data/Data';

const Admin = () => {


    return (
        <section className='admin'>
            {/* TITLE */}
            <div className="title">
                <p>Welcome to VUTUK,</p>
            </div>



            {/* SECTIONS */}
            <div className="admin-sections">


                <div className="add-new-btn">
                    <p>
                        <Link to='/addnew'>Add New</Link>
                    </p>
                </div>
            </div>
            <Data />



        </section>
    )
}

export default Admin